import './index.css'
import Login  from './pages/Login'
import AppLayout  from './components/Layout'
import { useUser } from './utils/useUser'

export default function App() {
  const { session, setSession } = useUser();

  if (!session) {
    return <Login 
              session={session}
              setSession={setSession}
            />
  }
  else {
    return <AppLayout 
        setSession={setSession} 
      />
  }
}