import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Spin, message, Card, Alert } from 'antd';

import {
  useLocalStorage
} from '../utils/local-storage';
import { useUser } from '../utils/useUser';

const { Title } = Typography;


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export default function AppInstallOauthRedirect() {
  
  const query = useQuery();
  const navigate = useNavigate();
  const { session } = useUser();
  const [savedCode, setCode, removeCode] = useLocalStorage('code_verifier', null);
  const [showSpin, setShowSpin] = useState(true);


  useEffect(() => {
      const code = query.get('code');
      const state = query.get('state')
      if (code){
        onSlackRedirect({code, state}).then(res => {
          removeCode(savedCode);
          navigate('/');
            message.success("You're all set! Enjoy! 🚀")
        }).catch(err => {
          setShowSpin(false);
          console.error(err)
        })
      } else {
        setShowSpin(false);
      }
  }, [session.access_token]);


  async function onSlackRedirect({code, state}) {
    return fetch(
      `https://chartpush-slack-api-cknd2zehxq-uc.a.run.app/finish_auth`, 
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          code,
          user_id: session.user.id,
          slack_destination_id: state,
        }),
      }
    ).then(res => {
      const json = res.json();
      return json;
    }).catch(err => console.error(err));
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
      <Card style={{width: '380px', marginBottom: '76px', paddingBottom: '24px'}}>
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', marginTop: '24px'}}>
          <img src={`https://upload.wikimedia.org/wikipedia/commons/d/d5/Slack_icon_2019.svg`} alt="cube logo" width='220'/>
          <Title style={{marginTop: '36px'}}level={5}> Slack authorization grant in progress... </Title>
          {showSpin ? <Spin /> : <Alert message="Something went wrong while trying to authorize... Please DM me on my Linkedin" type="error" showIcon/>}
        </div>
      </Card>
    </div>
  );
}