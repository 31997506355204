import React, { useState } from 'react';
import {
  ApiOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  UserOutlined,
  LogoutOutlined,
  ConsoleSqlOutlined,
  BarChartOutlined,

} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Avatar, Flex, Dropdown } from 'antd';
import { supabase } from '../utils/supabaseClient';
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('Workflows', 'workflows', <ClockCircleOutlined />),
  getItem('Connections', 'connections', <ApiOutlined />,[
    getItem('Sources', 'sources'),
    getItem('Destinations', 'destinations'),
  ]),
  getItem('SQL Console', 'sqlconsole', <ConsoleSqlOutlined />),
  getItem('Chart Editor', 'charteditor', <BarChartOutlined />),
  getItem('Settings', 'settings', <SettingOutlined />, [
    getItem('Tom', '7'),
    getItem('Bill', '8'),
    getItem('Alex', '9'),
  ]),
];

const accountItems = [
    getItem('Sign Out', '1', <LogoutOutlined />)
];


const AppLayout = ({ setSession }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [localTheme, setLocalTheme] = useState('dark');

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
            <Flex justify='space-between' align='center'>
            <div className="demo-logo-vertical" style={{marginLeft: 10}}>
                <Image
                    src="/chartpush.svg"
                    alt="logo"
                    height="36"
                    width="200px"
                    preview={false}
                />
            </div>
            
            <Dropdown
                menu={{
                    items: accountItems, 
                    onClick: async () => {
                        await supabase.auth.signOut()
                        setSession(null)
                    }
                }}
                trigger={['click']}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Avatar 
                        size={"large"} 
                        icon={<UserOutlined />} 
                        style={{marginRight: 20}}
                    />
                </a>
            </Dropdown>
            </Flex>
        </Header>
        <Layout>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Menu 
                    theme={localTheme} 
                    defaultSelectedKeys={[pathname.substring(1)]} 
                    mode="inline" 
                    items={items} 
                    onClick={(e) => {
                        if(['workflows', 'charteditor', 'sqlconsole', 'sources', 'destinations'].includes(e.key)){
                            navigate(e.key)
                        }  
                    }}
                />
            </Sider>
            <Layout>
                <Content
                style={{
                    margin: '0 16px',
                }}
                >
                <Breadcrumb
                    style={{
                    margin: '16px 0',
                    }}
                    items={[{ title: pathname.substring(1) }]}
                />
                    <Outlet />
                </Content>
                <Footer
                style={{
                    textAlign: 'center',
                }}
                >
                Chartpush ©{new Date().getFullYear()} Created by Jonathan Barone.
                </Footer>
            </Layout>
        </Layout>
    </Layout>
  );
};
export default AppLayout;