import { useState } from 'react'
import { Col, Row, Card, Flex } from 'antd'
import Plot from 'react-plotly.js';
import ChartConfigPanel from '../components/ChartConfigPanel';
import { templates } from '../utils/templates';


const dataSources = {
  col1: [1, 2, 3], // eslint-disable-line no-magic-numbers
  col2: [4, 3, 2], // eslint-disable-line no-magic-numbers
  col3: [17, 13, 9], // eslint-disable-line no-magic-numbers
};

const dataSourceOptions = Object.keys(dataSources).map((name) => ({
  value: name,
  label: name,
}));

const gridStyle =  {
  width: '16.66666666666667%',
  textAlign: 'center',
  cursor: 'pointer',
};


export default function ChartEditor() {

  const [data, setData] = useState([
    {
      type: 'scatter',
      mode: 'lines+markers',
      marker: {color: 'red'},
      x: [1, 2, 3], 
      y: [2, 6, 3],
    },{
      type: 'bar', 
      x: [1, 2, 3], 
      y: [2, 5, 3]
    }]);
  const [layout, setLayout] = useState( { title: 'A Fancy Plot'} );

  const onClickChartTemplate = (type) => {
    setLayout(templates[type]['layout']); 
    setData(templates[type]['data'])
  }

    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Templates">
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('bars')}>Bar</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('lines')}>Line</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('pie')}>Pie</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('indicator')}>Indicator</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('scatter')}>Scatter</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('bubble')}>Bubble</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('area')}>Area</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('sankey')}>Sankey</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('funnel')}>Funnel</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('box')}>Box Plots</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('histogram')}>Histogram</Card.Grid>
              <Card.Grid style={gridStyle} onClick={() => onClickChartTemplate('radar')}>Radar</Card.Grid>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginTop: 16}}>
          <Col span={12}>
            <ChartConfigPanel 
                data={data}
                setData={setData}
                layout={layout}
                setLayout={setLayout}
            />
          </Col>
          <Col span={12}>
            <Card style={{height: 616}}>
              <Plot
                data={data}
                layout={layout}
                config={{responsive: true}}
                style={{width: '100%', height: '100%'}}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
}
