import { useEffect, useState, createContext, useContext } from 'react';


export const UserContext = createContext(undefined);

export const MyUserContextProvider = (props) => {
  const { supabaseClient: supabase } = props;
  const [isLoadingData, setIsloadingData] = useState(false);
  const [session, setSession] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [customData, setCustomData] = useState({});

  const downloadImage = async (path) => {
    try {
      const { data, error } = await supabase.storage.from('avatars').download(path)
      if (error) {
        throw error
      }
      const url = URL.createObjectURL(data)
      setAvatarUrl(url)
    } catch (error) {
      console.log('Error downloading image: ', error.message)
    }
  }

  // const getUserDetails = () =>
  //   supabase.from('users').select('*').single();

  // useEffect(() => {
  //   if (user && !isLoadingData && !userDetails && !subscription) {
  //     setIsloadingData(true);
  //     Promise.allSettled([getUserDetails(), getSubscription()]).then(
  //       (results) => {
  //         const userDetailsPromise = results[0];
  //         const subscriptionPromise = results[1];

  //         if (userDetailsPromise.status === 'fulfilled'){
  //           setUserDetails(userDetailsPromise.value.data);
  //           if (userDetailsPromise.value.data.avatar_url){
  //            downloadImage(userDetailsPromise.value.data.avatar_url);
  //           }
  //         }

  //         if (subscriptionPromise.status === 'fulfilled')
  //           setSubscription(subscriptionPromise.value.data);

  //         setIsloadingData(false);
  //       }
  //     );
  //   } else if (!user && !isLoadingUser && !isLoadingData) {
  //     setUserDetails(null);
  //     setSubscription(null);
  //   }
  // }, [user, isLoadingUser]);

  const value = {
    session,
    setSession,
    customData,
    setCustomData
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a MyUserContextProvider.`);
  }
  return context;
};
