import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Button, Alert, message } from 'antd';
import { Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import { supabase } from '../utils/supabaseClient';
import { getDestinations } from '../services/destinations';

const { Title } = Typography;


const Destinations = () => {

const [hasDestinations, setHasDestinations] = useState(false);
const [destinations, setDestinations] = useState([]);
const navigate = useNavigate();

useEffect(() => {
  getDestinations().then(destinations => {
    setDestinations(destinations);
    setHasDestinations(destinations.length > 0)
  });
}, [])

const deleteDestination = (id) => {
  return supabase.from("destination").delete().eq("id", id)
      .then(() => {
          message.success(`Destination deleted successfully!`)
          navigate('/destinations');
      })
      .catch(error => {
          message.error(`Destination delete failed!`);
      });
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a onClick={() => navigate(`/destination/${record.key}`) }>Update</a>
        <a onClick={() => deleteDestination(record.key) }>Delete</a>
      </Space>
    ),
  },
];

return <div>
  <Space direction="vertical" style={{
      display: 'flex',
    }}>
  {!hasDestinations ? <Alert
      message="You don't have a destination configured yet"
      description={<Button type="primary" onClick={() => navigate('/destination/new')}>Add a Destination</Button>}
      type="info"
      showIcon
    /> : <Button 
          type="primary" 
          onClick={() => navigate('/destination/new')}
          style={{marginBottom: 12}}
          >
        Add a Destination
      </Button>}
  
  <Table columns={columns} dataSource={destinations} /> 
  </Space>
  </div>
};
export default Destinations;