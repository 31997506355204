import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Alert, message } from 'antd';
import { Typography } from 'antd';
import { useNavigate } from "react-router-dom";
import { supabase } from '../utils/supabaseClient';
import { getSources } from '../services/sources';

const { Title } = Typography;


const Sources = () => {

  const [hasSources, setHasSources] = useState(false);
  const [sources, setSources] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getSources().then(data => {
      setSources(data);
      setHasSources(data.length > 0)
    });
  }, [])

  const deleteSource = (id) => {
    return supabase.from("source").delete().eq("id", id)
        .then(() => {
            message.success(`Source deleted successfully!`)
            navigate('/sources');
        })
        .catch(error => {
            message.error(`Source delete failed!`);
        });
  }
  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => navigate(`/source/${record.key}`) }>Update</a>
          <a onClick={() => deleteSource(record.key) }>Delete</a>
        </Space>
      ),
    },
  ];

  return <div>
    <Space direction="vertical" style={{
        display: 'flex',
      }}>
    {!hasSources ? <Alert
        message="You don't have a source configured yet"
        description={
          <Button type="primary" onClick={() => navigate('/source/new')}>
            Add a Source
          </Button>}
        type="info"
        showIcon
      /> : <Button 
        type="primary" 
        onClick={() => navigate('/source/new')}
        style={{marginBottom: 12}}
        >
      Add a Source
    </Button>}
    
    <Table columns={columns} dataSource={sources}/> 
    </Space>
    </div>
};
export default Sources;