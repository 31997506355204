import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Input, Select, Space, Typography, Divider, message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { supabase } from '../utils/supabaseClient';
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from '../utils/useUser';
import {v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

const { Option } = Select;
const { Title } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};
const uploadLayout = {
    wrapperCol: {
      offset: 6,
      span: 16,
    },
  };
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

const Source = ({ addEdit }) => {

  const [form] = Form.useForm();
  const [sourceTypes, setSourceTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [serviceAccount, setServiceAccount] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const { session } = useUser();
  let location = useLocation();


  const navigate = useNavigate();

  useEffect(() => {
        if(addEdit === 'edit'){
            setSourceId(location.pathname.split('/')[2])

            supabase.from("source").select('name, source_type(name)').eq("id", location.pathname.split('/')[2]).then(Source => {
                form.setFieldsValue({
                    name: Source.data[0].name,
                    type: Source.data[0].source_type.name,
                    service_account: 'paste',
                    'input-creds': Source.serviceAccount
                });
                setServiceAccount(Source.serviceAccount);
                setCurrentFile(Source.filename);
            });
        }
    }, []);


  const onFinish = async (values) => {
    setLoading(true)
    let svcAccountJson;
    let jsonKeys = [];

    if (form.getFieldValue('type') === 'bigquery'){
        try {
            svcAccountJson = JSON.parse(serviceAccount);
        } catch(error) {
            message.error(`JSON credentials invalid!`);
        }
        jsonKeys = Object.keys(svcAccountJson);
    }

    if (values.type === 'bigquery' && (!jsonKeys.includes('private_key') || !jsonKeys.includes('client_email'))) {
        message.error(`Service account invalid!`);
        setLoading(false)
    }
    else {
        const data = {
          name: values.name,
          source_type_id: sourceTypes.filter(sourceType => sourceType.name === values.type)[0].id,
          user_id: session.user.id,
          filename: currentFile
        }
        onSubmit(data).then(res => {
            setLoading(false);
        });
    }
  };

  const onSubmit = async (data) => {
    return addEdit === 'add' ? createSource(data)
        : updateSource(sourceId, data);
  }

  const onTextChange = (e) => {
    setServiceAccount(e.target.value)
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    getSourceTypes();
  }, []);

  async function getSourceTypes() {
    const { data } = await supabase.from("source_type").select();
    setSourceTypes(data);
  }

//   const deleteSource = () => {
//     let data = {
//         filename: currentFile
//     }
//     return sourceService.delete(router.query.id, data)
//         .then(() => {
//             message.success(`Source deleted successfully!`)
//             navigate('/sources');
//         })
//         .catch(error => {
//             message.error(`Source deletion failed!`);
//         });
//   }

  const createSource = async (data) => {
    let id = uuidv4();
    const filePath = `${session.user.id}/${id}.json`;
    const blob = Buffer.from(serviceAccount);
    const { error } = await supabase.storage.from('connections').upload(filePath, blob);
    if (error) {
        throw error
    }
    await supabase.from("source").insert({ ...data, filename: filePath }).then(() => {
        message.success(`Source created successfully!`)
        navigate('/sources');
    })
    .catch(error => {
        message.error(`Source creation failed!`);
    });
  }

  const updateSource = async (id, data) => {
    await supabase.storage.from('connections').remove([data.filename]);
    let file_id = uuidv4();
    const filePath = `${session.user.id}/${file_id}.json`;

    const blob = Buffer.from(serviceAccount);
    var { error } = await supabase.storage.from('connections').upload(filePath, blob);
    if (error) {
        throw error
    }
    console.log({ ...data, filename: filePath })
    return supabase.from("source").update({ ...data, filename: filePath }).eq("id", id)
        .then(() => {
            message.success(`Source updated successfully!`)
            navigate('/sources');
        })
        .catch(error => {
            message.error(`Source update failed!`);
        });
  }

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = e => {
        setServiceAccount(e.target.result);
    };
    reader.readAsText(file);
    // Prevent upload
    return false;
  }

  return (
    <Card>
        <Title level={2}>Add a Source</Title>
        <Divider />
            <Form
                {...layout}
                form={form}
                name="create-source"
                onFinish={onFinish}
                style={{
                    maxWidth: 1200,
                }}
            >
            <Form.Item
                name="name"
                label="Name"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="type"
                label="Type"
                rules={[
                {
                    required: true,
                },
                ]}
            >
                <Select
                placeholder="Select a source type"
                allowClear
                options={sourceTypes.map(sourceType => ({value: sourceType.name, label: sourceType.name}))}
                />
            </Form.Item>
            <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('type') === 'bigquery' ? (
                        <Form.Item name="service_account" label="Credentials" rules={[{ required: true }]}>
                            <Select
                              placeholder="Provide service account key"
                            >
                              <Option value="paste">Paste JSON credentials</Option>
                              <Option value="upload">Upload service account file</Option>
                            </Select>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type || prevValues.service_account !== currentValues.service_account}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('type') === 'bigquery' && getFieldValue('service_account') === 'upload' ? (
                        <Form.Item {...uploadLayout} name="upload-creds" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true }]}>
                            <Dragger
                                name="upload-file"
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </Form.Item>
                    ) : getFieldValue('type') === 'bigquery' && getFieldValue('service_account') === 'paste' ? (
                        <Form.Item {...uploadLayout} name="input-creds" rules={[{ required: true }]}>
                          <TextArea rows={4} onChange={onTextChange}/>
                      </Form.Item>) : null
                    }
                  </Form.Item>
            <Form.Item {...tailLayout}>
                <Space>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
                <Button htmlType="button" onClick={onReset}>
                    Reset
                </Button>
                </Space>
            </Form.Item>
            </Form>
    </Card>
  );
};

export default Source;