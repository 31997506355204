import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Button, Alert } from 'antd';
import { Typography } from 'antd';
import { getSources } from '../services/sources';
import { getDestinations } from '../services/destinations';
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];
const data = [
  // {
  //   key: '1',
  //   name: 'John Brown',
  //   age: 32,
  //   address: 'New York No. 1 Lake Park',
  //   tags: ['nice', 'developer'],
  // },
  // {
  //   key: '2',
  //   name: 'Jim Green',
  //   age: 42,
  //   address: 'London No. 1 Lake Park',
  //   tags: ['loser'],
  // },
  // {
  //   key: '3',
  //   name: 'Joe Black',
  //   age: 32,
  //   address: 'Sydney No. 1 Lake Park',
  //   tags: ['cool', 'teacher'],
  // },
];

const Workflows = () => {

const [hasSources, setHasSources] = useState(false);
const [hasDestinations, setHasDestinations] = useState(false);
const navigate = useNavigate();

useEffect(() => {
  getSources().then(sources => {
    setHasSources(sources.length > 0)
  });

  getDestinations().then(destinations => {
    setHasDestinations(destinations.length > 0)
  });
}, [])

return <div>
  <Space direction="vertical" style={{
      display: 'flex',
    }}>
  {!hasSources && <Alert
      message="You don't have a source configured yet"
      description={<Button type="primary" onClick={() => navigate('/source/new')}>Add a Source</Button>}
      type="info"
      showIcon
    />}
  {!hasDestinations && <Alert
      message="You don't have a destination configured yet"
      description={<Button type="primary" onClick={() => navigate('/destination/new')}>Add a Destination</Button>}
      type="info"
      showIcon
    />}
  
  <Table columns={columns} dataSource={data} /> 
  </Space>
  </div>
};
export default Workflows;