import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from "./pages/ErrorPage";
import Workflows from './pages/Workflows';
import Sources from './pages/Sources';
import Source from './pages/Source';
import Destinations from './pages/Destinations';
import Destination from './pages/Destination';
import AppInstallOauthRedirect from './pages/AppInstallOauthRedirect';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import ChartEditor from './pages/ChartEditor';
import { supabase } from './utils/supabaseClient'
import { MyUserContextProvider } from './utils/useUser'

const router = createBrowserRouter([
  {
    path: "/",
    element: <ConfigProvider theme={{ token: { colorPrimary: '#1cb6be' } }}>
              <App />
            </ConfigProvider>,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to="/workflows" replace /> },
      {
        path: "workflows",
        element: <Workflows />,
      },
      {
        path: "charteditor",
        element: <ChartEditor />,
      },
      {
        path: "sources",
        element: <Sources />,
      },
      {
        path: "source",
        
        children:[{
          path: "new",
          element: <Source addEdit={'add'}/>,
        },
        {
          path: ":sourceId",
          element: <Source addEdit={'edit'}/>,
        }]
      },
      {
        path: "destinations",
        element: <Destinations />,
      },
      {
        path: "destination",
        
        children:[{
          path: "new",
          element: <Destination addEdit={'add'}/>,
        },
        {
          path: ":destinationId",
          element: <Destination addEdit={'edit'}/>,
        }]
      },
      {
        path: "oauth/callback",
        element: <AppInstallOauthRedirect />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MyUserContextProvider supabaseClient={supabase}>
    <RouterProvider router={router} />
    </MyUserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
