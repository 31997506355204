import { useEffect, useState } from 'react'
import { Card, Tabs, Input, Form, Button, Flex } from 'antd'
import Editor from '@monaco-editor/react';

const { TextArea } = Input;
  

export default function ChartConfigPanel({data, setData, layout, setLayout}) {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [dataHasError, setDataHasError] = useState(false);
    const [layoutHasError, setLayoutHasError] = useState(false);
    const [error, setError] = useState('');

  useEffect(() => {
   console.log(data)
   form.setFieldsValue({data: JSON.stringify(data, null, "\t")}) 
   form2.setFieldsValue({layout: JSON.stringify(layout, null, "\t")})
  }, [data])

    const onChange = (key) => {
      if (!isJsonString(key[0].value)) {
        if(key[0].name[0] === 'data'){
          setDataHasError(true)
        }
        if(key[0].name[0] === 'layout'){
          setLayoutHasError(true)
        }
      }
      else {
        if(key[0].name[0] === 'data'){
          setDataHasError(false)
          setData(JSON.parse(key[0].value))
        }
        if(key[0].name[0] === 'layout'){
          setLayoutHasError(false)
          setLayout(JSON.parse(key[0].value))
        }
        
      }
    };

    const isJsonString = (str) => {
      try {
          JSON.parse(str);
      } catch (e) {
          setError(String(e))
          return false;
      }
      return true;
    }

    return (
    <Card 
      style={{
        width: '100%',
      }}
    >
      <Tabs 
        defaultActiveKey="2"
        items={[
          {
            key: '2',
            label: 'Traces',
            children: <Form
                        name="data"
                        form={form}
                      
                        initialValues={{
                          remember: true,
                          data: JSON.stringify(data, null, "\t")
                        }}
                        onFieldsChange={(_, allFields) => {
                          onChange(allFields);
                        }}
                        autoComplete="off"
                      >
                        <Form.Item
                          name="data"
                          hasFeedback={true}
                          validateStatus={dataHasError ? "error" : "success"}
                          help={dataHasError ? error : ""}
                          rules={[
                            {
                              required: true,
                              message: 'Please input at least 1 trace!',
                            },
                          ]}
                        >
                          <Editor 
                            height="45vh" 
                            defaultLanguage="json"
                            onKey
                            options={{
                              minimap: {
                                enabled: false,
                              },
                              fontSize: 13,
                              wordWrap: "on",
                            }}/>
                        </Form.Item>
                        <Flex gap="small">
                          <Button type="primary"> Save </Button>
                          <Button> Open </Button>
                        </Flex>
                      </Form>
             
          },
          {
            key: '3',
            label: 'Layout',
            children: <Form
                        name="layout"
                        form={form2}
                        initialValues={{
                          remember: true,
                          layout: JSON.stringify(layout, null, "\t")
                        }}
                        onFieldsChange={(_, allFields) => {
                          onChange(allFields);
                        }}
                        autoComplete="off"
                      >
                        <Form.Item
                          name="layout"
                          hasFeedback={true}
                          validateStatus={layoutHasError ? "error" : "success"}
                          help={layoutHasError ? error : ""}
                        >
                          <Editor 
                            height="45vh" 
                            defaultLanguage="json"
                            onKey
                            options={{
                              minimap: {
                                enabled: false,
                              },
                              fontSize: 13,
                              wordWrap: "on",
                            }}/>
                          </Form.Item>
                          <Flex gap="small">
                            <Button type="primary"> Save </Button>
                            <Button> Open </Button>
                          </Flex>
                      </Form>
          },
        ]} 
      />
    </Card>
    );
}
