import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Input, Select, Space, Typography, Divider, message, Alert, Col } from 'antd';
import { supabase } from '../utils/supabaseClient';
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from '../utils/useUser';
import { createCodeVerifier } from '../utils/helpers';
import { useLocalStorage } from '../utils/local-storage';

const { Option } = Select;
const { Title } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};


const Destination = ({ addEdit }) => {

  const [form] = Form.useForm();
  const [destinationTypes, setDestinationTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [serviceAccount, setServiceAccount] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [destinationId, setDestinationId] = useState(null);
  const { session } = useUser();
  const [ savedCode, setCode, removeCode ] = useLocalStorage('code_verifier', null);
  let location = useLocation();
  const navigate = useNavigate();

  const generateSlackAuthorizeURL = () => {
    const redirectURL = 'https://app.chartpush.com/oauth/callback'
    const code = createCodeVerifier(50);
    setCode(code);
    return `https://slack.com/oauth/v2/authorize?scope=chat:write&redirect_uri=${redirectURL}&client_id=2252114450835.7398061266241&code=${code}&state=${destinationId}`
}

  useEffect(() => {
        if(addEdit === 'edit'){
            setDestinationId(location.pathname.split('/')[2])

            supabase.from("destination").select('name, destination_type(name)').eq("id", location.pathname.split('/')[2]).then(Destination => {
                form.setFieldsValue({
                    name: Destination.data[0].name,
                    type: Destination.data[0].destination_type.name,
                    // service_account: 'paste',
                    // 'input-creds': Destination.serviceAccount
                });
                // setServiceAccount(Destination.serviceAccount);
                // setCurrentFile(Destination.filename);
            });
        }
    }, []);


  const onFinish = async (values) => {
    setLoading(true)
    // let svcAccountJson;
    // try {
    //     svcAccountJson = JSON.parse(serviceAccount);
    // } catch(error) {
    //     message.error(`JSON credentials invalid!`);
    // }
    // let jsonKeys = Object.keys(svcAccountJson);
    // if (!jsonKeys.includes('private_key') || !jsonKeys.includes('client_email')) {
    //     message.error(`Service account invalid!`);
    //     setLoading(false)
    //   }
    // else {
        const data = {
          name: values.name,
          destination_type_id: destinationTypes.filter(destinationType => destinationType.name === values.type)[0].id,
        //   userId: user.id,
        //   serviceAccount: serviceAccount,
        //   filename: currentFile
        }
        onSubmit(data).then(res => {
            setLoading(false);
        });
    // }
  };

  const onSubmit = async (data) => {
    return addEdit === 'add' ? createDestination(data)
        : updateDestination(destinationId, data);
  }

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    getDestinationTypes();
  }, []);

  async function getDestinationTypes() {
    const { data } = await supabase.from("destination_type").select();
    setDestinationTypes(data);
  }

  const createDestination = async (data) => {
    await supabase.from("destination").insert({ ...data, user_id: session.user.id }).then((data) => {
        if (form.getFieldValue('type') === 'slack'){
            window.open(generateSlackAuthorizeURL(), "_self", "noreferrer")
        } else {
            message.success(`Destination created successfully!`)
            navigate('/destinations');
        } 
    })
    .catch(error => {
        message.error(`Destination creation failed!`);
    });
  }

  const updateDestination = (id, data) => {
    return supabase.from("destination").update({ ...data, user_id: session.user.id }).eq("id", id)
        .then(() => {
            if (form.getFieldValue('type') === 'slack'){
                window.open(generateSlackAuthorizeURL(), "_self", "noreferrer")
            } else {
                message.success(`Destination updated successfully!`)
                navigate('/destinations');
            } 
        })
        .catch(error => {
            console.log(error)
            message.error(`Destination update failed!`);
        });
  }



  return (
    <Card>
        <Title level={2}>Add a Destination</Title>
        <Divider />
            <Form
                {...layout}
                form={form}
                name="create-destination"
                onFinish={onFinish}
                style={{
                    maxWidth: 1200,
                }}
            >
            <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="type"
                label="Type"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select
                    placeholder="Select a destination type"
                    allowClear
                    options={destinationTypes.map(destinationType => ({value: destinationType.name, label: destinationType.name}))}
                />
                
            </Form.Item>
            <Form.Item 
            {...tailLayout}
            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
            >
                {({ getFieldValue }) =>
                        getFieldValue('type') === 'slack' && <Col span={18} >
                    <Alert
                        message="Clicking on Submit will start the installation of the slack app to the workspace of your choice."
                        type="info"
                        showIcon
                    />
                </Col>}
            </Form.Item>
            <Form.Item 
            {...tailLayout}
            >
                <Space>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
                <Button htmlType="button" onClick={onReset}>
                    Reset
                </Button>
                </Space>
            </Form.Item>
            </Form>
    </Card>
  );
};

export default Destination;