/**
 * lib/supabaseClient.js
 * Helper to initialize the Supabase client.
 */

 import { createClient } from '@supabase/supabase-js'

 const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
 const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

 export const insertConnectionCreds = async (filePath, blob, insertData) => {
    let { error } = await supabase.storage.from('connections').upload(filePath, blob)

    if (error) {
      throw error
    }

    error = await supabase.from('connections').insert([insertData], {
      returning: 'minimal', // Don't return the value after inserting
    })
   return error;
};
 
 export const supabase = createClient(supabaseUrl, supabaseAnonKey)