import { useEffect } from 'react'
import { Card, Flex, Image } from 'antd'
import { supabase } from '../utils/supabaseClient'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { Auth } from '@supabase/auth-ui-react'



const customTheme = {
    default: {
      colors: {
        brand: '#1cb6be',
        brandAccent: '#E86F6C',
        brandButtonText: 'black',
        // ..
      },
    },
}


export default function Login({session, setSession}) {

        useEffect(() => {
            supabase.auth.getSession().then(({ data: { session } }) => {
                setSession(session)
            })
        
            const {
                data: { subscription },
            } = supabase.auth.onAuthStateChange((_event, session) => {
                setSession(session)
            })
        
            return () => subscription.unsubscribe()
        }, [])

      return (<Flex gap="middle" align="center" justify="center" vertical>
        <Card style={{minWidth: 400, marginTop: 200}}>
          <Image
              src="/chartpush.svg"
              alt="logo"
              height="36"
              width="200px"
              preview={false}
          />
          <Auth 
            supabaseClient={supabase} 
            theme="default"
            providerScopes={{
              azure: 'email',
            }}
            appearance={{theme: ThemeSupa, variables: {
            default: {
              colors: {
                brand: '#1cb6be',
                brandAccent: '#6CE5E8',
                brandButtonText: 'black',
              },
            },
            },}} 
          />
        </Card>
      </Flex>
      )
  }