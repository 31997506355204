var x1 = [];
var x2 = [];
var y1 = [];
var y2 = [];
for (var i = 1; i < 500; i++) 
{
  var k = Math.random();
  x1.push(k*5);
  x2.push(k*10);
  y1.push(k);
  y2.push(k*2);
}

export const templates = {
    bars: {
        data: [{
            x: ['Feature A', 'Feature B', 'Feature C', 'Feature D', 'Feature E'],
            y: [20, 14, 23, 25, 22],
            marker:{
              color: ['rgba(204,204,204,1)', 'rgba(222,45,38,0.8)', 'rgba(204,204,204,1)', 'rgba(204,204,204,1)', 'rgba(204,204,204,1)']
            },
            type: 'bar'
        }],
        layout: {
            title: 'Least Used Feature'
        }
    },
    lines: {
        data: [{
            x: [1, 2, 3, 4, 5],
            y: [1, 3, 2, 3, 1],
            mode: 'lines',
            name: 'Solid',
            line: {
              dash: 'solid',
              width: 4
            }
          },
          {
            x: [1, 2, 3, 4, 5],
            y: [6, 8, 7, 8, 6],
            mode: 'lines',
            name: 'dashdot',
            line: {
              dash: 'dashdot',
              width: 4
            }
          },
          {
            x: [1, 2, 3, 4, 5],
            y: [11, 13, 12, 13, 11],
            mode: 'lines',
            name: 'Solid',
            line: {
              dash: 'solid',
              width: 4
            }
          },
          {
            x: [1, 2, 3, 4, 5],
            y: [16, 18, 17, 18, 16],
            mode: 'lines',
            name: 'dot',
            line: {
              dash: 'dot',
              width: 4
            }
          }],
          layout: {
            title: 'Line Dash',
            xaxis: {
              range: [0.75, 5.25],
              autorange: false
            },
            yaxis: {
              range: [0, 18.5],
              autorange: false
            },
            legend: {
              y: 0.5,
              traceorder: 'reversed',
              font: {
                size: 16
              }
            }
          }
    },
    pie: {
        data: [{
            values: [19, 26, 55],
            labels: ['Residential', 'Non-Residential', 'Utility'],
            type: 'pie'
        }],
        layout: {
            title: 'Basic Pie Chart',
        }
    },
    indicator: {
        data: [{
            type: "indicator",
            mode: "number+delta",
            value: 450,
            title: {
              text:
                "Accounts<br><span style='font-size:0.8em;color:gray'>Subtitle</span><br><span style='font-size:0.8em;color:gray'>Subsubtitle</span>"
            },
            delta: { reference: 400, relative: true },
          }
        ],
        layout: {
            // title: 'Indicator Chart',
        }
    },
    scatter: {
        data: [{
            x: ['South Korea', 'China', 'Canada'],
            y: [24, 10, 9],
            name: 'Gold',
            type: 'scatter',
            mode: 'markers'
          },{
            x: ['South Korea', 'China', 'Canada'],
            y: [13, 15, 12],
            name: 'Silver',
            type: 'scatter',
            mode: 'markers'
          },{
            x: ['South Korea', 'China', 'Canada'],
            y: [11, 8, 12],
            name: 'Bronze',
            type: 'scatter',
            mode: 'markers'
          }],
        layout: {
            scattermode: 'group',
            title: 'Grouped by Country',
            xaxis: {title: 'Country'},
            yaxis: {title: 'Medals'}
          }
    },
    bubble: {
        data: [{
            x: [1, 2, 3, 4],
            y: [10, 11, 12, 13],
            text: ['A<br>size: 40', 'B<br>size: 60', 'C<br>size: 80', 'D<br>size: 100'],
            mode: 'markers',
            marker: {
              size: [400, 600, 800, 1000],
              sizemode: 'area'
            }
          },{
            x: [1, 2, 3, 4],
            y: [14, 15, 16, 17],
            text: ['A</br>size: 40</br>sixeref: 0.2', 'B</br>size: 60</br>sixeref: 0.2', 'C</br>size: 80</br>sixeref: 0.2', 'D</br>size: 100</br>sixeref: 0.2'],
            mode: 'markers',
            marker: {
              size: [400, 600, 800, 1000],
              //setting 'sizeref' to lower than 1 decreases the rendered size
              sizeref: 2,
              sizemode: 'area'
            }
          },{
            x: [1, 2, 3, 4],
            y: [20, 21, 22, 23],
            text: ['A</br>size: 40</br>sixeref: 2', 'B</br>size: 60</br>sixeref: 2', 'C</br>size: 80</br>sixeref: 2', 'D</br>size: 100</br>sixeref: 2'],
            mode: 'markers',
            marker: {
              size: [400, 600, 800, 1000],
              //setting 'sizeref' to less than 1, increases the rendered marker sizes
              sizeref: 0.2,
              sizemode: 'area'
            }
          }],
        layout: {
            title: 'Bubble Chart Size Scaling',
            showlegend: false,
          }
    },
    area: {
        data:[
            {
                x: [1, 2, 3, 4],
                y: [0, 2, 3, 5],
                fill: 'tozeroy',
                type: 'scatter'
            },
            {
                x: [1, 2, 3, 4],
                y: [3, 5, 1, 7],
                fill: 'tonexty',
                type: 'scatter'
              }
        ],
        layout: {
            title: "Basic Area chart"
        }
    },
    sankey: {
        data: [{
            type: "sankey",
            orientation: "h",
            node: {
              pad: 15,
              thickness: 30,
              line: {
                color: "black",
                width: 0.5
              },
             label: ["A1", "A2", "B1", "B2", "C1", "C2"],
             color: ["blue", "blue", "blue", "blue", "blue", "blue"]
                },
          
            link: {
              source: [0,1,0,2,3,3],
              target: [2,3,3,4,4,5],
              value:  [8,4,2,8,4,2]
            }
        }],
        layout: {
            title: "Basic Sankey",
            font: {
              size: 10
            }
          }
    },
    funnel: {
        data: [{type: 'funnelarea', values: [5, 4, 3, 2, 1], text: ["The 1st", "The 2nd", "The 3rd", "The 4th", "The 5th"],
        marker: {colors: ["59D4E8", "DDB6C6", "A696C8", "67EACA", "94D2E6"],
                  line: {color: ["3E4E88", "606470", "3E4E88", "606470", "3E4E88"], width: [2, 1, 5, 0, 3]}},
        textfont: {family: "Old Standard TT", size: 13, color: "black"}, opacity: 0.65}],
        layout: {margin: {l: 200 , r: 200}, funnelmode: "stack", showlegend: 'True'}
    },
    box: {
        data: [{
            y: [1, 2, 3, 4, 4, 4, 8, 9, 10],
            type: 'box',
            name: 'Sample A',
            marker:{
              color: 'rgb(214,12,140)'
            }
          },{
            y: [2, 3, 3, 3, 3, 5, 6, 6, 7],
            type: 'box',
            name: 'Sample B',
            marker:{
              color: 'rgb(0,128,128)'
            }
          }],
        layout: {
            title: 'Colored Box Plot'
        }
    },
    histogram: {
        data: [{
            x: x1,
            y: y1,
            name: 'control',
            autobinx: false, 
            histnorm: "count", 
            marker: {
              color: "rgba(255, 100, 102, 0.7)", 
               line: {
                color:  "rgba(255, 100, 102, 1)", 
                width: 1
              }
            },  
            opacity: 0.5, 
            type: "histogram", 
            xbins: {
              end: 2.8, 
              size: 0.06, 
              start: .5
            }
          },{
            x: x2,
            y: y2, 
            autobinx: false, 
            marker: {
                    color: "rgba(100, 200, 102, 0.7)",
                     line: {
                      color:  "rgba(100, 200, 102, 1)", 
                      width: 1
              } 
                 }, 
            name: "experimental", 
            opacity: 0.75, 
            type: "histogram", 
            xbins: { 
              end: 4, 
              size: 0.06, 
              start: -3.2
          
            }
          }],
        layout:{
            bargap: 0.05, 
            bargroupgap: 0.2, 
            barmode: "overlay", 
            title: "Sampled Results", 
            xaxis: {title: "Value"}, 
            yaxis: {title: "Count"}
          }
    },
    radar: {
        data: [
            {
            type: 'scatterpolar',
            r: [39, 28, 8, 7, 28, 39],
            theta: ['A','B','C', 'D', 'E', 'A'],
            fill: 'toself',
            name: 'Group A'
            },
            {
            type: 'scatterpolar',
            r: [1.5, 10, 39, 31, 15, 1.5],
            theta: ['A','B','C', 'D', 'E', 'A'],
            fill: 'toself',
            name: 'Group B'
            }
        ],
        layout: {
            title: "Radar Chart",
            polar: {
              radialaxis: {
                visible: true,
                range: [0, 50]
              }
            }
          }
    }
}